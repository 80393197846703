<template>
  <v-container id="dashboard" fluid tag="section">
    <v-row>

      <!-- QUERY SETTINGS -->
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="4" lg="3">
            <DateRangePicker @update="onDateRangeUpdated"
              :def-dates="dates">
            </DateRangePicker>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <ScopeSelector vertical ref="exchanges-scope-selector"
              @org-selected="onOrganizationSelected"
              @mch-selected="onMerchantSelected"
              :preselected-merchant-id="$route.query.merchantId">
            </ScopeSelector>
          </v-col>
          <v-col cols="12" sm="4" lg="3">
            <v-btn block large color="purple darken-3" @click="exportExchanges">
              <v-icon left>mdi-file-export</v-icon>
              Download as Excel
            </v-btn>
            <v-btn block large text color="grey darken-3" @click="forceRefresh">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>

      <!-- LIST OF QPOINTS EXCHANGES -->
      <v-col cols="12">
        <v-card class="mt-0">
          <v-card-text>
            <SSRTable ref="list-exchanges"
              :data-url="loadUrl" :data-key="'exchanges'" :schema="schema">

              <div slot="redemption" slot-scope="{item}">
                {{ item.redeem_points }} {{ $t('views.qpointsExchanges.points') }}<br>
                = ${{ item.redeem_amount }}
              </div>

              <div slot="payment_order_id" slot-scope="{item}">
                <span class="clickable" v-if="item.payment_order_id"
                  @click="showOrderInfoDialog(item)">
                  {{ item.payment_order_id }}
                </span> 
              </div>

              <div slot="amount" slot-scope="{item}">
                ${{ Number(item.amount).toLocaleString() }}
              </div>

              <div slot="status" slot-scope="{item}">
                <div>
                  <v-chip small outlined color="green darken-1" v-if="!item.cancelled_at">
                    {{ $t('views.qpointsExchanges.redemption') }}<v-icon right>mdi-check</v-icon>
                  </v-chip>
                  <v-chip small outlined color="pink accent-3" v-else>
                    {{ $t('views.qpointsExchanges.redemption') }}<v-icon right>mdi-cancel</v-icon>
                  </v-chip>
                </div>
                <div v-if="item.payment_order_id">
                  <v-chip small outlined color="indigo lighten-1" v-if="item.payment_status == 'completed'">
                    {{ $t('views.qpointsExchanges.payment') }}<v-icon right>mdi-check</v-icon>
                  </v-chip>
                  <v-chip small outlined color="amber darken-3" v-if="item.payment_status == 'refund'">
                    {{ $t('views.qpointsExchanges.payment') }}<v-icon right>mdi-cancel</v-icon>
                  </v-chip>
                </div>
              </div>

              <div slot="user" slot-scope="{item}">
                {{ item.user? item.user.name: '' }}<br>
                {{ item.user? item.user.phone: '' }}
              </div>

              <div slot="actions" slot-scope="{item}">
                <v-btn rounded small color="blue" @click="showDetailDialog(item)">
                  <v-icon>mdi-information-variant</v-icon>
                  {{ $t('views.qpointsExchanges.more') }}
                </v-btn>
              </div>
            </SSRTable>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <!-- Dialog - Exchange detail and actions -->
    <v-dialog v-model="dialog.detail.show" width="50%">
      <v-card>
        <v-card-title>
          <strong>{{ $t('views.qpointsExchanges.dialog.exchange') }} #{{ dialog.detail.exchange.qpoints_order_id }}</strong>
        </v-card-title>

        <v-card-text>
          <v-row>
            <!-- Info iterator -->
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.exchangeId') }}</td>
                      <td>{{ dialog.detail.exchange.exchange_id }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.qpointsOrderId') }}</td>
                      <td>{{ dialog.detail.exchange.qpoints_order_id }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.vendor') }}</td>
                      <td>{{ dialog.detail.exchange.vendor }} ({{ dialog.detail.exchange.point_code }})</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.exchangedAt') }}</td>
                      <td>{{ dialog.detail.exchange.exchanged_at }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.amount') }}</td>
                      <td>${{ dialog.detail.exchange.amount }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.redeemAmount') }}</td>
                      <td>${{ dialog.detail.exchange.redeem_amount }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.redeemPoints') }}</td>
                      <td>{{ dialog.detail.exchange.redeem_points }} points</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.onlinePaid') }}</td>
                      <td>${{ dialog.detail.exchange.amount - dialog.detail.exchange.redeem_amount }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
            <v-col cols="6">
              <v-simple-table>
                <template v-slot:default>
                  <tbody>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.userName') }}</td>
                      <td>{{ dialog.detail.exchange.user? dialog.detail.exchange.user.name: '' }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.userPhone') }}</td>
                      <td>{{ dialog.detail.exchange.user? dialog.detail.exchange.user.phone: '' }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.qrpayOrderId') }}</td>
                      <td>
                        <span class="clickable" v-if="dialog.detail.exchange.payment_order_id"
                          @click="showOrderInfoDialog(dialog.detail.exchange)">
                          {{ dialog.detail.exchange.payment_order_id }}
                        </span>
                      </td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.paidAt') }}</td>
                      <td>{{ dialog.detail.exchange.created_at }}</td>
                    </tr>
                    <tr><td class="font-weight-medium">{{ $t('views.qpointsExchanges.dialog.cancelledAt') }}</td>
                      <td>{{ dialog.detail.exchange.cancelled_at }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="d-flex justify-end">
          <v-btn color="red"
            @click="requestReturnPoints">
            <v-icon left dark>mdi-keyboard-return</v-icon>{{ $t('views.qpointsExchanges.dialog.buttonReturnPoints') }} ({{ dialog.detail.exchange.redeem_points }})
          </v-btn>
          <v-btn color="pink" @click="showOrderInfoDialog(dialog.detail.exchange)">
            <v-icon left dark>mdi-cash-refund</v-icon>{{ $t('views.qpointsExchanges.dialog.buttonRefund') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog for showing detail and transactional history of the selected order -->
    <OrderDetailDialog :show.sync="dialog.orderInfo.show"
      :payment-order-id="dialog.orderInfo.paymentOrderId">
    </OrderDetailDialog>
  </v-container>
</template>

<script>
import SSRTable from '@/components/SSRTable';
import DateRangePicker from '@/components/DateRangePicker';
import ScopeSelector from '@/components/ScopeSelector';

import OrderDetailDialog from '@/views/dashboard/subviews/OrderDetailDialog';

import axios from 'axios';
import moment from 'moment';

export default {
  components: {
    SSRTable,
    DateRangePicker,
    ScopeSelector,
    OrderDetailDialog
  },

  mounted() {
    //NOTE: If a merchant ID is given in URL query, update scope filter immediately.
    if (this.$route.query.merchantId) {
      this.scope.merchant = { 'merchant_id': this.$route.query.merchantId };
    }
  },

  methods: {
    onDateRangeUpdated(dates) {
      this.dates = dates;
    },
    onOrganizationSelected(org) {
      this.scope.organization = org;
    },
    onMerchantSelected(merchant) {
      this.scope.merchant = merchant;
    },
    forceRefresh() {
      this.$refs['list-exchanges'].loadData();
    },

    /// Methods for exchange detail.
    showDetailDialog(exchange) {
      this.dialog.detail.exchange = exchange;
      this.dialog.detail.show = true;
    },
    requestReturnPoints() {
      let exchange = this.dialog.detail.exchange;
      if (!confirm(
        `Sure you want to revoke the exchange for returning ${exchange['redeem_points']}?\n`
        + `This cannot be undone.`
      ))
        return;
      
      axios.delete(
        `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/qpoints/exchanges/${exchange['exchange_id']}`
      )
      .then(response => {
        console.log(`Exchanged points successfully refunded.`, response.data);
        this.$notify({
          type: 'success',
          text: `Exchanged points successfully refunded.`
        });
      })
      .catch(err => {
        console.error(`Cannot refund the exchanged points`, err.response);
        this.$notify({
          type: 'error',
          text: `Cannot refund the exchanged points.`
        });
      })
      .finally(() => {
        this.forceRefresh();
      });
    },
    requestRefund() {

    },


    showOrderInfoDialog(exchange) {
      this.dialog.orderInfo.paymentOrderId = exchange['payment_order_id'];
      this.dialog.orderInfo.show = true;
    },

    exportExchanges() {
      let from = moment(this.dates[0]).startOf('day');
      let to = moment(this.dates[1]).endOf('day');
      window.open(`${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/qpoints/exchanges/export`
        + `?from=${from.unix()}&to=${to.unix()}`)
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
    }
  },

  data() {
    return {
      dates: [
        moment().startOf('day').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      ],
      scope: {
        organization: null,
        merchant: null
      },

      dialog: {
        detail: {
          show: false,
          exchange: { }
        },
        orderInfo: {
          show: false,
          paymentOrderId: ''
        }
      }
    };
  },

  computed: {
    loadUrl() {
      let from = moment(this.dates[0]).startOf('day');
      let to = moment(this.dates[1]).endOf('day');
      return `${process.env.VUE_APP_QRPAY_ADMIN_HOST}/api/vas/qpoints/exchanges?timeFormat=${encodeURIComponent('YYYY/M/D h:mm:ssA')}`
        + `&from=${from.unix()}&to=${to.unix()}`
        + (this.scope.organization? `&orgId=${this.scope.organization['id']}`: '')
        + (this.scope.merchant? `&merchantId=${this.scope.merchant['merchant_id']}`: '');
    },
    schema() {
      return [
        { value: 'merchant_name', text: this.$t('views.qpointsExchanges.merchantName') },
        { value: 'qpoints_order_id', text: this.$t('views.qpointsExchanges.qpointsOrderId') },
        { value: 'vendor', text: this.$t('views.qpointsExchanges.vendor') },
        { value: 'status', text: this.$t('views.qpointsExchanges.status') },
        { value: 'amount', text: this.$t('views.qpointsExchanges.amount'), align: 'end' },
        { value: 'redemption', text: this.$t('views.qpointsExchanges.redeemed'), align: 'end' },
        { value: 'user', text: this.$t('views.qpointsExchanges.user'), align: 'end' },
        { value: 'exchanged_at', text: this.$t('views.qpointsExchanges.exchangedAt'), sortable: false },
        { value: 'payment_order_id', text: this.$t('views.qpointsExchanges.paymentOrderId') },
        { value: 'actions', text: this.$t('views.qpointsExchanges.actions') }
      ];
    }
  }
}
</script>

<style scoped>
span.clickable {
  cursor: pointer;
  color: darkcyan;
}
</style>